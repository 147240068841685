import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PopularArticle from "./popular-article"

const Aside = () => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, limit: 4) {
          edges {
            node {
              ...PopularArticle
            }
          }
        }
      }
    }
  `)
  var edges = nanoCms.articles.edges
  return (
    <aside class="sidenav">
      <div class="ad"></div>
      <div class="popular-article">
        <h2 class="aside-h2">新着</h2>
        {edges.map((edge) => (
          <PopularArticle node={edge.node} />
        ))}
      </div>
    </aside>
  )
}

export default Aside
