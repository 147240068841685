import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const PopularArticle = ({ node }) => {
  return (
    <article class="articleItemAside">
      <div class="articleLeft">
        <Link to={`/articles/${node.url}`}>
          {node.ogpImage ? (
            <img
              src={node.ogpImage}
              alt="ogp"
              className="aside-content-left-picture"
            />
          ) : (
            <StaticImage
              src="../images/nanocms.png"
              alt="topの画像"
              className="aside-content-left-picture"
            />
          )}
        </Link>
      </div>
      <div class="articleRight">
        <Link to={`/articles/${node.url}`}>
          <h4>{node.title}</h4>
        </Link>
      </div>
    </article>
  )
}

export const query = graphql`
  fragment PopularArticle on NanoCMS_ArticleType {
    timestamp
    title
    description
    ogpImage
    category
    content
    updatedAt
    url
  }
`;

export default PopularArticle
