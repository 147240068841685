import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Aside from "../components/aside";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-markdown";
import "prismjs/components/prism-json";
import "prismjs/components/prism-yaml";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-cshtml";
import "prismjs/themes/prism-twilight.css";

const ArticleDetail = ({ data }) => {
  const article = data.nanoCms.article;

  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "鍼灸メニュー",
      link: "/menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "コラム一覧",
      link: "/pages",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
    {
      id: 5,
      name: "アクセス・お問い合わせ",
      link: "/#storelist",
      dropdown: false,
    },
  ];

  // prismjsの呼び出し
  useEffect(() => {
    // 全ての <code> タグを取得
    document.querySelectorAll("pre > code:not([class])").forEach((block) => {
      // classが未設定のものに対して、language-pythonを設定
      block.className = "language-python";
    });
    Prism.highlightAll();
  }, []);

  return (
    <Layout as="article" navLinks={navLinks} color={"rgb(64, 155, 57, 0.2)"}>
      <main>
        <div className="image-heading">
          <StaticImage
            src="../images/post-show-kamata.png"
            width={4000}
            alt="topの画像"
          />
        </div>
        <div class="cntWrapper mrt-70">
          <ui class="breadcrumb">
            <li>
              <a href="/">TOP</a>
            </li>
            <li>
              <a>{article.category || `pages`}</a>
            </li>
            <li>{article.title}</li>
          </ui>
          <div class="cntWrapperIndex">
            <div class="mainnav">
              <article class="post">
                <div class="articleBar">
                  <div class="categoryBar">{article.category}</div>
                  <div class="postDate">{article.data}</div>
                </div>
                <h1>{article.title}</h1>
                <div class="subTitle">{article.description}</div>
                {article.ogpImage ? (
                  <img
                    src={article.ogpImage}
                    alt="ogp"
                    className="featuredImage"
                  />
                ) : (
                  <StaticImage
                    src="../images/nanocms.png"
                    alt="ogp"
                    className="featuredImage"
                  />
                )}
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
                <hr />
              </article>
            </div>
            <Aside />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const article = data.nanoCms.article;
  return (
    <Seo
      title={article.title}
      description={article.description}
      cover={article.ogpImage}
    />
  );
};

export const query = graphql`
  query ($timestamp: Int!) {
    nanoCms {
      article(timestamp: $timestamp) {
        title
        description
        ogpImage
        category
        content
        updatedAt
        timestamp
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
export default ArticleDetail;
